import { useQuery, gql } from "@apollo/client";
import {
  CalculationName as CalculationNameQuery,
  CalculationNameVariables
} from "./schema/CalculationName";

const queryCalculationName = gql`
  query CalculationName(
    $useCaseKey: String!
    $useCaseVersion: String
    $calculationId: ID!
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculation(calculationId: $calculationId) {
          inputData {
            detail {
              metaData {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export default function CalculationName({
  useCaseKey,
  useCaseVersion = null,
  calculationId
}: CalculationNameVariables) {
  const { data } = useQuery<CalculationNameQuery, CalculationNameVariables>(
    queryCalculationName,
    {
      variables: { useCaseKey, useCaseVersion, calculationId }
    }
  );

  const calculationName =
    data?.cloudPlatform.useCase?.calculation?.inputData.detail?.metaData
      .displayName;
  return <div>{calculationName || calculationId}</div>;
}
