import { ErrorBox } from "components/error";
import React, { useContext } from "react";
import { Permission } from ".";
import useRequestUserPermissions from "./useRequestUserPermissions";

type RestrictedRealmContextType = {
  debug?: boolean;
};

export interface RestrictedRealmContextProviderProps
  extends RestrictedRealmContextType {
  children: React.ReactNode;
}

const RestrictedRealmContext = React.createContext<RestrictedRealmContextType>({
  debug: false
});

export function useRestrictedRealmContext() {
  return useContext(RestrictedRealmContext);
}

/**
 * Provides realm context to all `RestrictedRealm` child components if permissions can be loaded.
 * In case of an error retrieving user permissions, the content is hidden and an error is displayed.
 */
export default function RestrictedRealmContextProvider({
  children,
  ...contextProps
}: RestrictedRealmContextProviderProps) {
  const { error } = useRequestUserPermissions([] as unknown as [Permission]);

  if (error)
    return (
      <ErrorBox apolloError={error} title="Unable to access user permissions" />
    );

  return (
    <RestrictedRealmContext.Provider value={contextProps}>
      {children}
    </RestrictedRealmContext.Provider>
  );
}
