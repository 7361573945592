import { Grid, LinearProgress } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import LinkIconButton from "components/base/LinkIconButton";
import { ErrorPage } from "components/error";
import { Permission, RestrictedRealm } from "features/access-management";
import { ActionBarContent } from "features/shell";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { createRoute, useSearchQueryParam } from "utils/url";
import { useProjectsDataWithPolling } from "./hooks/projectsHooks";
import ProjectCard from "./ProjectCard";
import ProjectStateFilter from "./ProjectStateFilter";

export default function Projects() {
  const { data, loading, error: projectsError } = useProjectsDataWithPolling();
  const [projectState, setProjectState] = useSearchQueryParam("projectState");

  const projects = useMemo(() => {
    if (!data) return [];
    if (!isEmpty(projectState)) {
      return data.filter(project => {
        return project.state === projectState;
      });
    } else return data;
  }, [data, projectState]);
  const history = useHistory();

  if (loading) return <LinearProgress />;

  if (projectsError)
    return (
      <ErrorPage
        title="An error occurred while loading the projects"
        message={projectsError.message}
        redirect={true}
        handelClick={() =>
          history.push(createRoute(`${process.env.PUBLIC_URL}/`))
        }
        buttonTitle="Go home"
      />
    );

  return (
    <RestrictedRealm requiredPermissions={[Permission.ReadProjects]}>
      <ActionBarContent>
        <RestrictedRealm
          requiredPermissions={[Permission.ChangeProjects]}
          silent
        >
          <LinkIconButton
            to="/projects/create"
            title="Add project"
            testId="add-project"
            icon={AddCircle}
          />
        </RestrictedRealm>
        <ProjectStateFilter onStateFilterChanged={setProjectState} />
      </ActionBarContent>
      <Grid container justifyContent="center" spacing={4}>
        {projects?.map(project => {
          const { id } = project;
          return <ProjectCard key={id!} project={project} />;
        })}
      </Grid>
    </RestrictedRealm>
  );
}
