import { gql, useMutation } from "@apollo/client";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import { ErrorBoxDialog } from "components/error";
import copy from "copy-to-clipboard";
import {
  CreateStreamingUrl,
  CreateStreamingUrlVariables
} from "./schema/CreateStreamingUrl";
export const mutationCreateStreamingUrl = gql`
  mutation CreateStreamingUrl($projectId: ID!, $refreshToken: String!) {
    projects {
      createStreamingUrl(projectId: $projectId, refreshToken: $refreshToken)
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    marginRight: 5
  },
  secondaryButton: {
    marginRight: 5
  }
}));

export default function ProjectStreamingButtons({
  projectId,
  isAvailable
}: {
  projectId: string;
  isAvailable: boolean;
}) {
  const classNames = useStyles();

  const [
    createStreamingUrlMutation,
    { error: mutationError, reset: resetError }
  ] = useMutation<CreateStreamingUrl, CreateStreamingUrlVariables>(
    mutationCreateStreamingUrl,
    { onError: error => console.log(error) }
  );

  const createStreamingUrl = async () => {
    const auth = await Auth.currentSession();
    const refreshToken = auth.getRefreshToken().getToken();
    const result = await createStreamingUrlMutation({
      variables: {
        projectId,
        refreshToken
      }
    });
    return result?.data?.projects.createStreamingUrl;
  };

  return (
    <>
      <Tooltip title="Connect to this project.">
        <Button
          color="primary"
          variant="contained"
          className={classNames.button}
          disabled={!isAvailable}
          data-testid="create-streaming-url"
          onClick={async () => {
            const url = await createStreamingUrl();
            if (url) window.open(url, "_blank");
          }}
          endIcon={<OpenInNewIcon />}
        >
          Start SCS
        </Button>
      </Tooltip>
      <Tooltip title="Can be used in the AppStream Client for Windows.">
        <Button
          variant="outlined"
          className={classNames.secondaryButton}
          disabled={!isAvailable}
          data-testid="copy-streaming-url"
          onClick={async () => {
            const url = await createStreamingUrl();
            if (url) copy(url);
          }}
          endIcon={<FileCopyIcon />}
        >
          Copy Streaming URL
        </Button>
      </Tooltip>
      <Tooltip title="Can be bookmarked to connect to this project immediately.">
        <Button
          variant="outlined"
          className={classNames.secondaryButton}
          data-testid="copy-connect-url"
          onClick={() => {
            const url = `${window.location.origin}${window.location.pathname}/connect`;
            if (url) copy(url);
          }}
          endIcon={<FileCopyIcon />}
        >
          Copy Connect URL
        </Button>
      </Tooltip>

      <ErrorBoxDialog apolloError={mutationError} onClose={resetError} />
    </>
  );
}
