import { useQuery, gql } from "@apollo/client";
import {
  QueryUseCaseName,
  QueryUseCaseNameVariables
} from "./schema/QueryUseCaseName";

export const queryUseCaseName = gql`
  query QueryUseCaseName($useCaseKey: String!, $useCaseVersion: String!) {
    cloudPlatform {
      useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        detail {
          name
        }
      }
    }
  }
`;

export default function useQueryUseCaseName(
  variables: QueryUseCaseNameVariables
) {
  return useQuery<QueryUseCaseName, QueryUseCaseNameVariables>(
    queryUseCaseName,
    {
      variables,
      fetchPolicy: "cache-first"
    }
  );
}
