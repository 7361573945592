import { useQueryUseCaseData } from "./topics/calculate/calculationHooks";

export default function UploadedDataName({
  useCaseKey,
  useCaseVersion = null,
  dataId
}: {
  useCaseKey: string;
  useCaseVersion: string | null;
  dataId?: string;
}) {
  const { data: useCaseData } = useQueryUseCaseData({
    useCaseKey,
    useCaseVersion
  });
  const currentData = useCaseData?.cloudPlatform.useCase?.data.filter(
    d => d.detail?.key.split("/").slice(-1)[0] === dataId
  )[0];
  return <div>{currentData?.detail?.metaData.displayName}</div>;
}
