import { Typography } from "@mui/material";
import { HTMLAttributes } from "react";
import {
  useMutationUpdateCapacity,
  useQueryWorkspace
} from "../workspaceHooks";
import EditCapacityForm from "./EditCapacityForm";

export default function Capacity({
  workspaceId,
  className,
  editable
}: {
  workspaceId: string;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  editable: boolean;
}) {
  const { data: workspaceCapacity } = useQueryWorkspace({
    workspaceId
  });

  const { updateCapacity, data: mutatedCapacity } = useMutationUpdateCapacity();
  const capacity =
    mutatedCapacity?.projects.workspace?.updateCapacity ||
    workspaceCapacity?.projects.workspace?.capacity;

  if (!capacity) return <div />;

  return (
    <div className={className}>
      <Typography variant="h5" gutterBottom>
        Seat capacity
      </Typography>
      <EditCapacityForm
        max={capacity.maxCapacity}
        min={capacity.minCapacity}
        workspaceId={workspaceId}
        allowToEdit={editable}
        updateCapacity={updateCapacity}
      />
    </div>
  );
}
