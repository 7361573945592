import { useQuery, gql } from "@apollo/client";
import { Grid, LinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import NoItemFound from "components/base/NoItemFound";
import { ErrorBox } from "components/error";
import { useEffect } from "react";
import { notNullOrUndefined } from "utils/typescript";
import useQueryProjectById from "./hooks/useQueryProjectById";
import Project from "./Project";
import {
  WorkspaceStatus,
  WorkspaceStatusVariables
} from "./schema/WorkspaceStatus";

export const queryWorkspaceStatus = gql`
  query WorkspaceStatus($workspaceId: ID!) {
    projects {
      workspace(workspaceId: $workspaceId) {
        id
        name
        isAvailable
        capacity {
          computeCapacityStatus {
            available
            inUse
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: { color: theme.palette.common.white },
  progress: { marginTop: -4 }
}));

export default function ProjectWrapper({ projectId }: { projectId: string }) {
  const classNames = useStyles();
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
    startPolling,
    stopPolling
  } = useQueryProjectById({
    projectId
  });

  const project = projectData?.projects.project;
  const workspaceId = project?.workspace?.id;

  const { data: workspaceStatus, error: workspaceStatusError } = useQuery<
    WorkspaceStatus,
    WorkspaceStatusVariables
  >(queryWorkspaceStatus, {
    skip: !workspaceId,
    variables: { workspaceId: workspaceId! },
    pollInterval: 15000
  });

  useEffect(() => {
    if (project?.state === "Scheduled" || project?.state === "InProgress") {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [project?.state, startPolling, stopPolling]);

  if (projectData && !project) {
    return (
      <NoItemFound
        title="We could not find this project. Would you like to create one?"
        linkTarget="/projects/create"
        linkCaption="Create a new project"
      />
    );
  }
  const errors = [projectError, workspaceStatusError].filter(
    notNullOrUndefined
  );
  if (errors.length > 0) return <ErrorBox apolloError={errors} />;
  return (
    <Grid item xs={12}>
      {projectLoading && (
        <Grid item xs={12}>
          <LinearProgress className={classNames.progress} />
        </Grid>
      )}
      {project && (
        <Project
          project={project}
          workspaceStatus={workspaceStatus?.projects.workspace}
        />
      )}
    </Grid>
  );
}
