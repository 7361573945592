import DataGrid, {
  Column,
  ColumnFixing,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  IColumnProps,
  Item,
  Paging,
  Scrolling,
  Selection,
  Sorting,
  Toolbar
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import "./dataGridView.css";

import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CustomStore from "devextreme/data/custom_store";
import { Properties } from "devextreme/ui/data_grid";
import { RenderableColumnDefinition } from "./gridTypings";

const useStyles = makeStyles(theme => ({
  gridRoot: { overflow: "hidden", height: "100%" },
  item: {
    minHeight: 50
  }
}));

type DxGridProps = Omit<Properties, "columns">;

interface BaseGridProps<
  TRowData,
  TColDef extends Omit<IColumnProps, "cellRenderer">
> extends DxGridProps {
  /** Column definitions each consisting of:
   *
   *    @param colDef a devextreme-react/data-grid column definition without `cellRender`
   *    @param renderer a typed version of `cellRender`
   */
  columns?: RenderableColumnDefinition<TColDef, TRowData>[];
  isLoading?: boolean;
  withToolbar?: boolean;
  toolbarItemRender?: () => React.ReactNode;
  toolbarItemLocation?: string;
  allowGrouping?: boolean;
  load?: any;
  allowSelection?: boolean;
  children?: React.ReactNode;
  showFilterRow?: boolean;
}

interface RowDataGridProps<
  TRowData,
  TColDef extends Omit<IColumnProps, "cellRenderer">
> extends BaseGridProps<TRowData, TColDef> {
  dataSource: TRowData[];
}

interface DataSourceGridProps<
  TRowData,
  TColDef extends Omit<IColumnProps, "cellRenderer">
> extends BaseGridProps<TRowData, TColDef> {
  dataSource: CustomStore;
}

export type GridProps<
  TRowData,
  TColDef extends Omit<IColumnProps, "cellRenderer">
> =
  | RowDataGridProps<TRowData, TColDef>
  | DataSourceGridProps<TRowData, TColDef>;

export const TestIds = {
  loadingIndicator: "loadingIndicator",
  gridContent: "dataGrid"
};

const DataGridView = <TRowData extends {}>({
  columns,
  isLoading,
  withToolbar,
  toolbarItemRender,
  toolbarItemLocation = "after",
  allowGrouping = false,
  showFilterRow = true,
  load,
  allowSelection = true,
  children,
  dataSource,
  ...dataGridProps
}: GridProps<TRowData, IColumnProps>) => {
  const classes = useStyles();

  const remoteOperations = dataSource instanceof CustomStore;

  const gridProps = {
    cellHintEnabled: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    showBorders: true,
    height: "100%",
    width: "100%",
    columnAutoWidth: true,
    remoteOperations,
    ...dataGridProps
  };

  const { height } = gridProps;

  return (
    <div className={classes.gridRoot}>
      {isLoading && <LinearProgress data-testid={TestIds.loadingIndicator} />}
      <div
        data-testid={TestIds.gridContent}
        style={{ height: typeof height == "function" ? height() : height }}
      >
        <DataGrid dataSource={dataSource} {...gridProps}>
          <Scrolling
            mode={remoteOperations ? "virtual" : "standard"}
            columnRenderingMode="standard"
          />
          <Paging enabled={remoteOperations} />
          <GroupPanel visible={allowGrouping} />
          {allowGrouping && <Grouping autoExpandAll={true} />}
          {allowSelection && <Selection mode="single" />}
          <Sorting mode="multiple" />
          <FilterRow visible={showFilterRow} />
          <HeaderFilter visible={true} />
          <ColumnFixing enabled={true} />
          {columns?.map(({ colDef, render }, index) => {
            return (
              <Column
                {...colDef}
                cellRender={render}
                alignment="left"
                key={index}
              />
            );
          })}
          {withToolbar && toolbarItemRender ? (
            <Toolbar>
              <Item
                location={toolbarItemLocation}
                render={toolbarItemRender}
                cssClass={classes.item}
              />
            </Toolbar>
          ) : null}
          {children}
        </DataGrid>
      </div>
    </div>
  );
};
export default DataGridView;
