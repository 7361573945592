import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorStack, simplifyErrorStack } from "./errorUtils";

const useStyles = makeStyles(theme => ({
  errorStack: {
    margin: theme.spacing(1, 0, 0, 1),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    overflow: "auto"
  },
  content: {
    overflowWrap: "break-word"
  }
}));

export default function TraceableErrorDetail({
  errorStacks,
  maxHeight
}: {
  errorStacks: ErrorStack[];
  maxHeight?: number;
}) {
  const classNames = useStyles();

  return (
    <Grid item container lg spacing={1}>
      {errorStacks.map((errorStack, index) => (
        <Grid item container key={index} spacing={1} direction="column">
          <div
            className={classNames.errorStack}
            style={{ maxHeight: maxHeight || "auto" }}
          >
            {simplifyErrorStack(errorStack).map(({ content }, index) => (
              <Grid item key={index} lg>
                <Typography className={classNames.content}>
                  {content}
                </Typography>
              </Grid>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
