import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TagBox } from "devextreme-react";
import { difference } from "lodash";
import { useState } from "react";
import "./userGroups.css";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  }
}));

type UserGroupProps = {
  userGroups: string[];
  userName: string;
  allGroups?: string[];
  onUserGroupAdded: (user: string, group: string) => void;
  onUserGroupRemoved: (user: string, group: string) => void;
  loadingGroups: boolean;
  label?: string;
  height?: number;
};

export default function UserGroups({
  userGroups,
  userName,
  allGroups = [],
  onUserGroupAdded,
  onUserGroupRemoved,
  loadingGroups,
  label,
  height
}: UserGroupProps) {
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (options: any) => {
    const addedGroups = difference(options, userGroups);
    if (addedGroups[0]) {
      onUserGroupAdded(userName, addedGroups[0]);
    } else {
      const deletedGroups = difference(userGroups, options);
      if (deletedGroups[0]) onUserGroupRemoved(userName, deletedGroups[0]);
    }
  };
  return (
    <div className={classes.cell} data-testid="select-group">
      {loadingGroups ? <LinearProgress data-testid="loading-groups" /> : null}
      <TagBox
        items={allGroups}
        value={userGroups}
        multiline={true}
        onValueChange={handleChange}
        placeholder={undefined}
        showDropDownButton
        focusStateEnabled={true}
        onFocusIn={() => setIsFocused(true)}
        onFocusOut={() => setIsFocused(false)}
        width={"100%"}
        label={label}
        labelMode="floating"
        style={{
          height: height ? height : "100%",
          borderColor: isFocused ? "#3AA9AE" : "rgba(0, 0, 0, 0.23)",
          borderWidth: isFocused ? 2 : 1
        }}
        noDataText="no groups"
      />
    </div>
  );
}
