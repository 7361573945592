import { useMutation } from "@apollo/client";
import { AddCircle } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import FullSize from "components/base/FullSize";
import LinkIconButton from "components/base/LinkIconButton";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import DataGridView from "components/grid/DataGridView";
import { ActionBarContent } from "features/shell";
import {
  DeleteUseCase,
  DeleteUseCaseVariables,
  mutationDeleteUseCase
} from "features/use-case";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import UseCaseToolsPanel from "./UseCaseToolsPanel";
import { UseCaseManagementOverview_cloudPlatform_useCases } from "./hooks/schema/UseCaseManagementOverview";
import {
  queryUseCaseManagementList,
  useUseCaseManagementOverview
} from "./hooks/useCaseManagementHooks";

export default function UseCaseManager() {
  const [selectedRow, setSelectedRow] =
    useState<UseCaseManagementOverview_cloudPlatform_useCases>();
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = React.useState(false);

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const history = useHistory();

  const { data, loading: loadingUseCases } = useUseCaseManagementOverview();
  const [lazyUseDeleteUseCase] = useMutation<
    DeleteUseCase,
    DeleteUseCaseVariables
  >(mutationDeleteUseCase, {
    refetchQueries: [{ query: queryUseCaseManagementList }]
  });

  const useCases = useMemo(() => {
    if (!data) return [];
    let result = data.cloudPlatform.useCases;
    if (!result) return [];
    return result;
  }, [data]);

  return (
    <>
      <ActionBarContent>
        <LinkIconButton
          to={"/use-case-manager/create"}
          title="Add use case"
          testId="add-use-case"
          icon={AddCircle}
        />
      </ActionBarContent>
      {openRemoveUserDialog ? (
        <ConfirmGridActionDialog
          message={`You are about to remove ${selectedRow!.detail.name}`}
          onConfirm={async () => {
            await lazyUseDeleteUseCase({
              variables: { useCaseKey: selectedRow!.detail.key }
            });
          }}
          open={openRemoveUserDialog}
          onClose={handleCloseRemoveUserDialog}
        />
      ) : null}
      <FullSize id="use-case-management-table">
        <DataGridView
          dataSource={useCases || []}
          columns={[
            {
              colDef: { dataField: "detail.key", caption: "Key", groupIndex: 0 }
            },
            {
              colDef: { dataField: "detail.name", caption: "Name", width: 300 }
            },
            {
              colDef: {
                dataField: "detail.version",
                caption: "Version",
                width: 75
              }
            },
            {
              colDef: { dataField: "detail.tags", caption: "Tags", width: 300 }
            },
            {
              colDef: {
                dataField: "detail.description",
                caption: "Description",
                width: 300
              }
            }
          ]}
          allowGrouping
          onContentReady={params => {
            const grid = params.component;
            grid.selectRowsByIndexes([1]);
          }}
          withToolbar
          toolbarItemRender={() => (
            <UseCaseToolsPanel
              history={history}
              selectedRow={selectedRow}
              setOpenRemoveUserDialog={setOpenRemoveUserDialog}
            />
          )}
          onSelectionChanged={row => setSelectedRow(row.selectedRowsData[0])}
        />
        {loadingUseCases && <LinearProgress />}
      </FullSize>
    </>
  );
}
