import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export const useStyles = makeStyles((theme: Theme) => ({
  deleteIcon: {
    display: "none",
    pointerEvents: "none",
    padding: 6
  },
  publishIcon: {
    padding: 6
  },
  iconColor: {
    color: theme.palette.primary.main
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  list: {
    maxWidth: 220,
    height: 26,
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    paddingLeft: theme.spacing(1)
  },
  listItem: {
    maxWidth: "100%",
    flex: "0 0 auto",
    paddingLeft: 8,
    paddingRight: 8,
    marginRight: 4,
    color: "#FFFFFF",
    border: "solid 1px #FFFFFF",
    borderRadius: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: 4,
    display: "flex",
    alignItems: "center"
  }
}));

type TagsType = {
  tags: string[] | null;
  setShowAllTags?: (show: boolean) => void;
};

const Tags: React.FC<TagsType> = ({ tags = [], setShowAllTags }) => {
  const { list, listItem } = useStyles({});

  return tags ? (
    <ul className={list}>
      {tags.map((tag, index) => (
        <li
          key={index}
          className={listItem}
          onMouseOver={() => setShowAllTags && setShowAllTags(true)}
          onMouseLeave={() => setShowAllTags && setShowAllTags(false)}
        >
          <Typography variant="caption">{tag}</Typography>
        </li>
      ))}
    </ul>
  ) : null;
};
export default Tags;
