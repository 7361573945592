import {
  MutationHookOptions,
  gql,
  useMutation,
  useQuery
} from "@apollo/client";
import { DataCount, DataCountVariables } from "./schema/DataCount";
import {
  ScheduleCalculation,
  ScheduleCalculationVariables
} from "./schema/ScheduleCalculation";
import { UseCaseData, UseCaseDataVariables } from "./schema/UseCaseData";

const query = gql`
  query UseCaseData(
    $useCaseKey: String!
    $useCaseVersion: String
    $skipUntilAddress: String
    $limit: Int
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        data(skipUntilAddress: $skipUntilAddress, limit: $limit) {
          address
          detail {
            metaData {
              creator {
                firstName
                lastName
                name
              }
              displayName
            }
            key
            lastModified
            size
            bucketName
          }
        }
      }
    }
  }
`;

const dataCountQuery = gql`
  query DataCount($useCaseKey: String!, $useCaseVersion: String) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        dataCount
      }
    }
  }
`;
export const useQueryUseCaseData = (variables: UseCaseDataVariables) =>
  useQuery<UseCaseData, UseCaseDataVariables>(query, {
    variables
  });

export const useQueryUseCaseDataCount = (variables: DataCountVariables) =>
  useQuery<DataCount, DataCountVariables>(dataCountQuery, { variables });
const mutation = gql`
  mutation ScheduleCalculation(
    $useCaseKey: String!
    $useCaseVersion: String
    $dataSourceAddress: String!
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        scheduleCalculation(dataSourceAddress: $dataSourceAddress)
      }
    }
  }
`;

export const useMutationScheduleCalculation = (
  options?: MutationHookOptions<
    ScheduleCalculation,
    ScheduleCalculationVariables
  >
) =>
  useMutation<ScheduleCalculation, ScheduleCalculationVariables>(
    mutation,
    options
  );
