import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function DeleteUserAction(params: {
  data: { name: string };
  handleUserDeleteRequest: (userName: string) => void;
}) {
  const {
    data: { name: userName },
    handleUserDeleteRequest
  } = params;

  return (
    <IconButton
      data-testid="delete-user"
      onClick={() => {
        handleUserDeleteRequest(userName);
      }}
      size="large">
      <DeleteIcon />
    </IconButton>
  );
}
