import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  tabsRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white
  },
  tableView: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "auto"
  },
  icon: {
    marginLeft: 4
  },
  tabs: {
    height: 45,
    "& .dx-tabs-wrapper": {
      display: "flex",
      alignItems: "center",
      height: "100%"
    },
    "& .dx-tab": {
      color: theme.palette.primary.main,
      height: "100%",
      borderRight: "solid 1px rgba(0, 0, 0, 0.12)"
    },
    "& .dx-tab.dx-tab-selected": {
      color: theme.palette.primary.dark,
      borderBottom: `solid 2px ${theme.palette.primary.dark}`,
      borderTop: `solid 1px ${theme.palette.common.white}`,
      fontWeight: "bold",
      borderRight: "none",
      marginTop: -1
    },
    "& .dx-tab.dx-state-focused::after": {
      border: "none",
      borderBottom: `solid 1px ${theme.palette.primary.dark}`,
      borderRight: "solid 1px rgba(0, 0, 0, 0.12)",
      borderLeft: "solid 1px rgba(0, 0, 0, 0.12)"
    },
    "& :nth-child(1) > :last-child": {
      borderLeft: "none"
    },
    "& :nth-child(1) > :first-child": {
      borderRight: "none",
      borderLeft: "none"
    }
  },
  multiView: {
    height: "calc(100% - 46px)",
    border: "solid 1px rgba(0,0,0,0.12)",
    borderBottom: "none"
  },
  tabRenderRoot: { display: "flex", alignItems: "center" }
}));
