import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import Link from "components/base/Link";
import React from "react";
import { useStyles } from "./headerStyle";

type HeaderProps = {
  name: string;
  tableDeepLinks?: boolean;
  toCalculationResultTable?: any;
  backgroundColor: string;
};

const Header = ({
  name,
  tableDeepLinks,
  toCalculationResultTable,
  backgroundColor
}: HeaderProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { header, left, right, icon } = useStyles({});
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={clsx(header, backgroundColor)}>
      <div className={left} data-testid="table-name">
        {name}
      </div>
      <div className={right}>
        {tableDeepLinks && toCalculationResultTable && (
          <div style={{ display: "flex" }}>
            <IconButton
              aria-label="menu"
              onClick={handleClick}
              data-testid="table-option"
            >
              <MenuIcon className={icon} fontSize="small" />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                component={Link}
                to={toCalculationResultTable}
                data-testid="show-table"
              >
                <ListItemText primary="Show only this table" />
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
