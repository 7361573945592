import { Auth, Storage } from "aws-amplify";
import { getConfigValue } from "utils/config";
import { v4 as uuidv4 } from "uuid";

export const cognitoIdentity = `aws.cognito.identity-id.${getConfigValue(
  "IDENTITY_POOL_ID"
)}`;

interface AmplifyStoragePutResult {
  key: String; // S3 Object key
}

interface NamedCognitoUser {
  // aws amplify does not provide proper typings
  username: string;
}

export interface Progress {
  loaded: number;
  total: number;
}

export async function uploadTemporaryObject(file: Blob, displayName: string) {
  try {
    var credentials = await Auth.currentUserCredentials();
    var user = (await Auth.currentAuthenticatedUser()) as
      | NamedCognitoUser
      | undefined;

    if (!user || !credentials)
      throw new Error("File upload not possible without authenticated user.");

    const { key: s3ObjectKey } = (await Storage.put(uuidv4(), file, {
      level: "private",
      metadata: {
        creator: user.username,
        "display-name": displayName
      }
    })) as AmplifyStoragePutResult;

    return `s3://${getConfigValue("DATA_SOURCE_UNVERIFIED_BUCKET")}/private/${
      credentials.identityId
    }/${s3ObjectKey}`;
  } catch (error) {
    if (
      error === "cannot get guest credentials when mandatory signin enabled"
    ) {
      // cypress workaround: we ignore the specific error that is thrown in cypress non-e2e test run and return a fake s3 path
      return `s3://${getConfigValue(
        "DATA_SOURCE_UNVERIFIED_BUCKET"
      )}/private/fake/key`;
    } else {
      throw error;
    }
  }
}
