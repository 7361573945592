import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
  InputSchema,
  InputSchemaVariables,
  InputSchema_cloudPlatform_useCase_data
} from "./schema/InputSchema";
import {
  QueryInputData,
  QueryInputDataVariables
} from "./schema/QueryInputData";

export const schemaQuery = gql`
  query InputSchema(
    $useCaseKey: String!
    $useCaseVersion: String
    $keys: [String]
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        data(keys: $keys) {
          tableSet {
            tables {
              schema {
                columns {
                  typeName
                  nullable
                  name
                  displayName
                }
                name
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export const useQueryInputSchema = (variables: InputSchemaVariables) => {
  const client = useApolloClient();
  const [inputData, setInputData] =
    useState<InputSchema_cloudPlatform_useCase_data>();

  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const loadAndTransform = useCallback(
    async (variables: InputSchemaVariables) => {
      try {
        const { data, loading } = await client.query<
          InputSchema,
          InputSchemaVariables
        >({
          query: schemaQuery,
          variables
        });

        const inputDataSchemaAndAddress = data?.cloudPlatform.useCase?.data[0];

        setInputData(inputDataSchemaAndAddress);
        setLoadingData(loading);
      } catch (error) {
        setLoadingData(false);
        if (error instanceof ApolloError) {
          setError(error);
        }
      }
    },
    [client]
  );
  const inputSchema =
    inputData?.tableSet.tables.map((t: any) => t?.schema) || [];
  const inputColumnDefs = (schemaName: string) => {
    const schema = inputSchema?.filter(
      (schema: any) => schema && schema.name === schemaName
    );
    return schema?.[0]?.columns.map(({ name }: { name: string }) => {
      return {
        colDef: {
          dataField: name,
          caption: name,
          allowFiltering: false,
          allowSorting: false
        }
      };
    });
  };
  useEffect(() => {
    loadAndTransform(variables);
  }, [loadAndTransform, variables]);
  return {
    data: {
      inputSchema,
      inputColumnDefs,
      loadingData,
      error
    }
  };
};

export const inputDataQuery = gql`
  query QueryInputData(
    $useCaseKey: String!
    $useCaseVersion: String
    $keys: [String]
    $names: [String]
    $skip: Int
    $take: Int
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        data(keys: $keys) {
          tableSet {
            tables(names: $names) {
              rows(skip: $skip, take: $take)
              rowCount
            }
          }
        }
      }
    }
  }
`;
export const useCalculationInputData = () => {
  const client = useApolloClient();
  const loadInputData = useCallback(
    async (
      useCaseKey: string,
      useCaseVersion: string | null,
      dataKey: string,
      name: string,
      skip: number,
      take: number
    ) => {
      const result = await client.query<
        QueryInputData,
        QueryInputDataVariables
      >({
        query: inputDataQuery,
        variables: {
          useCaseKey,
          useCaseVersion,
          keys: [dataKey],
          names: [name],
          skip,
          take
        }
      });
      if (!result) {
        return null;
      }
      if (result.errors) {
        throw new Error(result.errors.toString());
      }
      const { useCase } = result.data.cloudPlatform;
      return useCase?.data[0]?.tableSet.tables[0];
    },
    [client]
  );
  return { loadInputData };
};
