import { useReducer } from "react";

export interface State {
  firstName: string;
  lastName: string;
  email: string;
  groups: string[];
}

export type Action =
  | { type: "SET_FIRST_NAME"; value: string }
  | { type: "SET_LAST_NAME"; value: string }
  | { type: "SET_EMAIL"; value: string }
  | { type: "ADD_GROUP"; value: string }
  | { type: "REMOVE_GROUP"; value: string }
  | { type: "RESET" };

export default function useCreateUserReducer(initialState: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_FIRST_NAME":
        return { ...state, firstName: action.value };
      case "SET_LAST_NAME":
        return { ...state, lastName: action.value };
      case "SET_EMAIL":
        return { ...state, email: action.value };
      case "ADD_GROUP":
        return { ...state, groups: [...state.groups, action.value] };
      case "REMOVE_GROUP":
        return {
          ...state,
          groups: state.groups.filter(group => group !== action.value)
        };
      case "RESET":
        return { ...initialState };
      default:
        throw new Error();
    }
  }

  return useReducer(reducer, initialState);
}
