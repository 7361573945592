/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CloudPermissions {
  CalculateUseCases = "CalculateUseCases",
  ChangeProjects = "ChangeProjects",
  ChangeUseCaseData = "ChangeUseCaseData",
  ChangeUseCases = "ChangeUseCases",
  ChangeUserAccess = "ChangeUserAccess",
  ChangeWorkspaces = "ChangeWorkspaces",
  CreateUser = "CreateUser",
  DeleteProjects = "DeleteProjects",
  DeletePublicApiRequests = "DeletePublicApiRequests",
  DeleteUseCases = "DeleteUseCases",
  DeleteUser = "DeleteUser",
  DownloadCalculationRule = "DownloadCalculationRule",
  ReadAllCalculations = "ReadAllCalculations",
  ReadAllUseCaseData = "ReadAllUseCaseData",
  ReadConfiguration = "ReadConfiguration",
  ReadDataSources = "ReadDataSources",
  ReadOtherUsers = "ReadOtherUsers",
  ReadProjects = "ReadProjects",
  ReadPublicApi = "ReadPublicApi",
  ReadPublicApiRequests = "ReadPublicApiRequests",
  ReadUseCases = "ReadUseCases",
  ReadVisualizations = "ReadVisualizations",
  ReadWorkspaces = "ReadWorkspaces"
}

export enum JobState {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

export enum ProjectState {
  Created = "Created",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

export enum ReleaseType {
  Initial = "Initial",
  Major = "Major",
  Minor = "Minor",
  Patch = "Patch"
}

export enum ScalingType {
  ScaleIn = "ScaleIn",
  ScaleOut = "ScaleOut"
}

export interface AddDataCellInput {
  key: string;
  value?: JsonValue | null;
}

export interface AddDataTableInput {
  name: string;
  rows: AddDataCellInput[][];
}

export interface ProjectDefinitionInput {
  name: string;
  description?: string | null;
  image?: string | null;
  workspaceId: string;
  databaseTemplateId: string;
  tags?: string[] | null;
}

export interface UseCaseDefinitionInput {
  key: string;
  name: string;
  tags: string[];
  calculationRuleAddress: string;
  image?: string | null;
  description?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
