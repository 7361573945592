import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  cancelButton: {
    textTransform: "capitalize"
  },
  removeButton: {
    textTransform: "capitalize",
    color: "#FFFFFF"
  },
  dialogText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));
const ConfirmGridActionDialog = ({
  message,
  open,
  onClose,
  onConfirm
}: {
  message: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgb(0,0,0,0.2)"
        }
      }}
      PaperProps={{
        style: {
          boxShadow: "none"
        }
      }}
    >
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          <span>{message}</span>
          <span>Do you want to continue?</span>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          className={classes.cancelButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          data-testid="confirm-delete-user"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          className={classes.removeButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmGridActionDialog;
