import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Button, Divider, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox, ErrorBoxDialog } from "components/error";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import DataGridView from "components/grid/DataGridView";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { DeepOmit } from "utils/typescript";
import { createRoute } from "utils/url";
import DeleteUserAction from "./DeleteUserAction";
import UserGroups from "./UserGroups";
import UserName from "./UserName";
import UserPermissions from "./UserPermissions";
import { getAllUserGroups as getUsedGroups } from "./getAllUserGroups";
import { Users_accessManagement_users } from "./hooks/schema/Users";
import {
  useQueryGetGroupsList,
  useQueryGetUsersList,
  usersList
} from "./hooks/usersHooks";
import {
  AddUserToGroup,
  AddUserToGroupVariables
} from "./schema/AddUserToGroup";
import { DeleteUser, DeleteUserVariables } from "./schema/DeleteUser";
import {
  RemoveUserFromGroup,
  RemoveUserFromGroupVariables
} from "./schema/RemoveUserFromGroup";

export const addUserToGroupMutation = gql`
  mutation AddUserToGroup($group: String!, $user: String!) {
    accessManagement {
      attachGroup(group: $group, user: $user) {
        name
      }
    }
  }
`;

export const deleteUserFromGroupMutation = gql`
  mutation RemoveUserFromGroup($group: String!, $user: String!) {
    accessManagement {
      detachGroup(group: $group, user: $user) {
        name
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation DeleteUser($name: String!) {
    accessManagement {
      deleteUser(name: $name)
    }
  }
`;

export interface IUserDataProvider {
  data: DeepOmit<Users_accessManagement_users, "__typename">;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    height: "100%"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  left: { display: "flex", alignItems: "center" },
  actions: { display: "flex", alignItems: "center" },
  button: {
    color: "#FFFFFF",
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  usersText: { fontSize: "1.25rem" },
  divider: {
    color: "#E4E6EF",
    height: 20,
    width: 2,
    margin: theme.spacing(0, 2)
  },
  usersCount: {
    color: "#7E8299",
    fontWeight: 500
  },
  skeletonText: {
    width: 200
  },
  skeletonRect: {
    width: "100%",
    height: 500
  },
  grid: { height: 400 },
  cancelButton: {
    textTransform: "capitalize"
  },
  removeButton: {
    textTransform: "capitalize",
    color: "#FFFFFF"
  },
  dialogText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  minWidth: { minWidth: 30 },
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  overflow: { overflow: "visible !important" }
}));

export default function Users() {
  const classNames = useStyles();
  const client = useApolloClient();
  const history = useHistory();

  const [
    loadUsers,
    { data: users, loading: loadingUsers, error: getUsersError }
  ] = useQueryGetUsersList();

  const [
    deleteUser,
    {
      error: deleteUserError,
      reset: resetDeleteUserError,
      loading: deleteUserLoading
    }
  ] = useMutation<DeleteUser, DeleteUserVariables>(deleteUserMutation, {
    refetchQueries: [{ query: usersList }]
  });
  const { data: groups, loading: loadingGroups } = useQueryGetGroupsList();

  const [
    deleteUserFromGroup,
    {
      loading: deleteUserFromGroupLoading,
      error: deleteUserFromGroupError,
      reset: resetDeleteUserFromGroupError
    }
  ] = useMutation<RemoveUserFromGroup, RemoveUserFromGroupVariables>(
    deleteUserFromGroupMutation,
    {
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: usersList }]
    }
  );
  const [
    addUserToGroup,
    {
      loading: addUserToGroupLoading,
      error: addUserToGroupError,
      reset: resetAddUserToGroupError
    }
  ] = useMutation<AddUserToGroup, AddUserToGroupVariables>(
    addUserToGroupMutation,
    {
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: usersList }]
    }
  );

  const [openRemoveUserDialog, setOpenRemoveUserDialog] = React.useState(false);
  const [userName, setUserName] = React.useState("");

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const isLoading =
    loadingUsers ||
    loadingGroups ||
    addUserToGroupLoading ||
    deleteUserFromGroupLoading ||
    deleteUserLoading;

  const mutationError =
    deleteUserError || deleteUserFromGroupError || addUserToGroupError;

  const allUsers = users?.accessManagement.users || [];
  const usedGroups = getUsedGroups(allUsers);
  const allGroups =
    groups?.accessManagement.groups.map(group => group.name) || [];

  const filterDataSource = (data: any) => {
    data.dataSource.postProcess = (results: any) => {
      results = usedGroups.map(name => ({ text: name, value: name }));
      return results;
    };
  };

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <div className={classNames.root}>
      <div className={classNames.header}>
        <div className={classNames.left}>
          <h5 className={classNames.usersText}>Users</h5>
          {allUsers ? (
            <>
              <Divider orientation="vertical" className={classNames.divider} />
              <Typography
                className={classNames.usersCount}
                data-testid="users-count"
              >{`${allUsers?.length} Total`}</Typography>
            </>
          ) : null}
        </div>
        <div className={classNames.actions}>
          <Tooltip title="Create user">
            <div>
              <Button
                className={classNames.button}
                variant="contained"
                data-testid="add-user-button"
                onClick={() => history.push(createRoute(`/users/create`))}
              >
                Add user
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Refresh">
            <div>
              <Button
                variant="contained"
                data-testid="refresh-button"
                className={classNames.button}
                onClick={() => loadUsers()}
              >
                Refresh
              </Button>
            </div>
          </Tooltip>
        </div>
        {openRemoveUserDialog ? (
          <ConfirmGridActionDialog
            message={`You are about to remove ${userName}`}
            onConfirm={() => deleteUser({ variables: { name: userName } })}
            open={openRemoveUserDialog}
            onClose={handleCloseRemoveUserDialog}
          />
        ) : null}
        <ErrorBoxDialog
          apolloError={mutationError}
          onClose={() => {
            handleCloseRemoveUserDialog();
            if (deleteUserError) resetDeleteUserError();
            if (deleteUserFromGroupError) resetDeleteUserFromGroupError();
            if (addUserToGroupError) resetAddUserToGroupError();
          }}
        />
      </div>
      <DataGridView<Users_accessManagement_users>
        dataSource={allUsers}
        isLoading={isLoading}
        showFilterRow={false}
        allowSelection={false}
        height={getUsersError ? 35 : "100%"}
        onCellPrepared={e => {
          if (e.column.dataField === "groups") {
            e.cellElement.classList.add(classNames.overflow);
          }
        }}
        columns={[
          {
            colDef: {
              dataField: "name",
              caption: "Name",
              sortOrder: "asc"
            },
            render: p => <UserName data={p.data} />
          },
          {
            colDef: {
              dataField: "groups",
              caption: "Groups",
              allowSorting: false,
              selectedFilterOperation: ["="],
              headerFilter: {
                dataSource: filterDataSource
              },
              calculateFilterExpression: (filterValue: string) => {
                return function (data: Users_accessManagement_users) {
                  return (
                    (data.groups.map(group => group.name) || []).indexOf(
                      filterValue
                    ) !== -1
                  );
                };
              }
            },

            render: ({ data }) => (
              <UserGroups
                userGroups={data.groups.map(group => group.name)}
                userName={data.name}
                allGroups={allGroups}
                loadingGroups={
                  addUserToGroupLoading || deleteUserFromGroupLoading
                }
                onUserGroupAdded={(user: string, group: string) =>
                  addUserToGroup({ variables: { user, group } })
                }
                onUserGroupRemoved={(user, group) =>
                  deleteUserFromGroup({ variables: { group, user } })
                }
              />
            )
          },
          {
            colDef: {
              dataField: "actions",
              caption: "Actions",
              width: 130,
              allowFiltering: false,
              allowSorting: false
            },
            render: ({ data }) => (
              <div className={classNames.cell}>
                <UserPermissions userName={data.name!} client={client} />
                <DeleteUserAction
                  data={data}
                  handleUserDeleteRequest={(userName: string) => {
                    setUserName(userName);
                    setOpenRemoveUserDialog(true);
                  }}
                />
              </div>
            )
          }
        ]}
      />
      {getUsersError ? <ErrorBox apolloError={getUsersError} /> : null}
    </div>
  );
}
