import { GetApp } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoTooltip from "components/base/InfoTooltip";
import Link from "components/base/Link";
import { formatDistanceToNow } from "date-fns";
import FileSaver from "file-saver";
import React from "react";
import { createBlobFromBytesArray } from "utils/blob";
import ApiDataView from "./ApiDataView";
import { useQueryApiDetail, useQueryApiKeys } from "./hooks/apiDetailHooks";
import { useQueryApiDocumentation } from "./hooks/apiDocumentationHook";

const formatAsReadableDate = (dateString: string) =>
  formatDistanceToNow(Date.parse(dateString), {
    includeSeconds: true,
    addSuffix: true
  });

export const useStyles = makeStyles(theme => ({
  panel: {
    backgroundColor: theme.palette.background.paper
  },
  panelProjects: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: 0
    }
  },
  panelDetails: {
    padding: theme.spacing(4, 4, 0, 4),
    alignItems: "flex-start"
  },
  panelDefault: {
    padding: theme.spacing(3, 4, 0, 4),
    flexWrap: "nowrap"
  },
  panelProject: {
    padding: theme.spacing(4, 4, 3, 4)
  },
  projectInformation: { padding: theme.spacing(0, 4, 3, 4) },
  heading: {
    flex: "0 1 auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: "bold"
  },
  info: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1)
  },
  downloadButton: { cursor: "pointer" },
  dataView: {
    flexWrap: "nowrap"
  },
  button: {
    color: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  },
  padding: {
    padding: theme.spacing(4, 4, 0, 4)
  },
  divider: { width: "100%", backgroundColor: "rgba(0, 0, 0, 0.08)" },
  flex: { display: "flex", alignItems: "center" },
  date: {
    color: "rgba(0,0,0,.2)",
    fontSize: 12
  },
  margin: {
    marginTop: theme.spacing(1)
  },
  skeletons: { display: "flex" }
}));

type APIDetailViewProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const APIDetailView: React.FC<APIDetailViewProps> = ({
  useCaseKey,
  useCaseVersion
}) => {
  const css = useStyles({});
  const { data: apiKeysData, loading: isLoadingApiKeys } = useQueryApiKeys();
  const lazyQueryApiDocumentation = useQueryApiDocumentation();

  const { data: apiData, loading: isLoadingApiDataDetail } = useQueryApiDetail({
    useCaseKey,
    useCaseVersion
  });

  const DownloadButton = ({
    apiKeyId,
    prefix = "default"
  }: {
    apiKeyId?: string | null;
    prefix?: string;
  }) => {
    return (
      <Tooltip
        title={
          <Typography>
            {`Download the ${prefix} specification zip file which describe the public api interface`}
          </Typography>
        }
      >
        <GetApp
          color="secondary"
          className={css.downloadButton}
          data-testid={`${prefix}-download-public-api`}
          onClick={async e => {
            e.stopPropagation();
            const documentation = await lazyQueryApiDocumentation({
              useCaseKey,
              useCaseVersion,
              apiKeyId
            });
            if (documentation) {
              FileSaver.saveAs(
                createBlobFromBytesArray(documentation, "application/zip"),
                `API_${prefix}_${useCaseKey}@${useCaseVersion}.zip`
              );
            }
          }}
        />
      </Tooltip>
    );
  };

  const apiKeys = apiKeysData?.cloudPlatform.publicApi.apiKeys;
  return (
    <Paper elevation={0} square>
      <Grid container spacing={0} alignItems="center" className={css.panel}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          className={css.panelDetails}
        >
          <Typography color="primary">API DETAIL</Typography>
          <Link to="./api-tester">
            <Button
              data-testid="api-tester"
              className={css.button}
              variant="contained"
              color="primary"
            >
              API TESTER
            </Button>
          </Link>
        </Grid>
        <Grid item container alignItems="center" className={css.panelDefault}>
          {isLoadingApiDataDetail ? (
            <div className={css.skeletons}>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="circular" width={20} height={20} />
            </div>
          ) : (
            <>
              <InfoTooltip
                typography
                title="This information applies to all projects. The download is available when no project has this option"
              >
                <Typography variant="h5" className={css.heading}>
                  General information
                </Typography>
              </InfoTooltip>
              <DownloadButton />
            </>
          )}
        </Grid>

        <ApiDataView data={apiData} loading={isLoadingApiDataDetail} />
        <Divider className={css.divider} />

        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          className={css.panelProject}
        >
          {isLoadingApiKeys ? (
            <>
              <Skeleton variant="text" width={200} />
              <Skeleton variant="circular" width={20} height={20} />
            </>
          ) : (
            <InfoTooltip
              typography
              title="Information by project, each download will contain all needed
             information for its project"
            >
              <Typography variant="h5" className={css.heading}>
                Project-specific information
              </Typography>
            </InfoTooltip>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={css.projectInformation}
        >
          {isLoadingApiKeys
            ? [1, 2, 3].map(i => (
                <div className={css.panelProjects} key={i}>
                  <div className={css.flex}>
                    <Skeleton variant="text" width={150} />
                    <Skeleton variant="circular" width={20} height={20} />
                  </div>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={250} />
                </div>
              ))
            : apiKeys &&
              apiKeys
                .filter(({ enabled }) => enabled)
                .map(({ name, description, lastUpdatedDate, id }) => {
                  const date = formatAsReadableDate(lastUpdatedDate);
                  return (
                    <div key={id} className={css.panelProjects}>
                      <div className={css.flex}>
                        <Typography variant="subtitle1" className={css.heading}>
                          {name}
                        </Typography>
                        <DownloadButton apiKeyId={id} prefix={name} />
                      </div>

                      <Typography
                        className={css.date}
                      >{`Updated  ${date}`}</Typography>
                      <Typography className={css.margin}>
                        {description}
                      </Typography>
                    </div>
                  );
                })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default APIDetailView;
