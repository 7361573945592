import { Chip } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  tags: {
    listStyle: "none",
    padding: theme.spacing(1, 4),
    display: "flex",
    margin: 0,
    flexWrap: "wrap",
    width: "100%"
  },
  tagChip: {
    padding: "6px 16px",
    textAlign: "center",
    background: "#FFFFFF",
    marginRight: 5,
    marginTop: 5,
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    overflow: "hidden"
  },
  tagLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block"
  }
}));

type TagsProps = {
  tags: string[];
};

const Tags: React.FC<TagsProps> = ({ tags = [] }) => {
  const jss = useStyles({});
  if (tags.length === 0) return null;
  return (
    <ul className={jss.tags}>
      {tags.map((tag: string) => (
        <Chip
          key={tag}
          label={tag}
          className={jss.tagChip}
          classes={{
            label: jss.tagLabel
          }}
        />
      ))}
    </ul>
  );
};
export default Tags;
