import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: "rgba(0, 0, 0, 0.54)",
    border: `solid 1px ${theme.palette.error.main}`,
    padding: 16,
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  }
}));

export default function ErrorMessage({
  className,
  message
}: {
  className?: string;
  message: string;
}) {
  const { errorMessage } = useStyles();
  return message ? (
    <div data-testid="error-message">
      <div className={[errorMessage, className || ""].join(" ")}>{message}</div>
    </div>
  ) : null;
}
