import { Error } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import {
  DateTickerCellRenderer,
  UserNameCellRenderer
} from "components/grid/CellRenderers";
import DataGridView from "components/grid/DataGridView";
import { tooltipFormatter } from "components/grid/dateFormatter";
import { formatDistanceStrict } from "date-fns";
import { debounce, find, get } from "lodash";
import React, { useCallback, useEffect } from "react";
import { useSearchQueryParam } from "utils/url";
import { useQueryUseCaseCalculations } from "./hooks/AllCalculationsResultsHooks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "solid 1px #dedede",
    marginBottom: theme.spacing(2)
  },
  title: {
    borderBottom: `solid 3px ${theme.palette.primary.main}`,
    display: "inline-block",
    color: "#788791",
    marginBottom: 0
  },
  flex: { display: "flex", alignItems: "center" },
  errorIcon: {
    marginLeft: theme.spacing(1)
  },
  dialogContent: {
    overflow: "hidden",
    padding: 0,
    "&:first-child": {
      paddingTop: 0
    }
  },
  customWidth: {
    maxWidth: "none",
    minWidth: 400
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  tooltip: {
    backgroundColor: "#fff",
    border: "solid 1px rgb(0, 0, 0, 0.9)",
    borderRadius: 0,
    color: "rgb(0, 0, 0, 0.9)"
  }
}));

const UseCaseCalculationsResults = ({
  signedInUser,
  useCaseKey,
  useCaseVersion,
  navigateToCalculate,
  navigateToCalculation
}) => {
  const {
    root,
    title,
    header,
    flex,
    errorIcon,
    dialogContent,
    button,
    customWidth,
    tooltip
  } = useStyles({});
  const [filterValues, setFilterValues] = React.useState([]);

  const [calculationId, setCalculationId] =
    useSearchQueryParam("calculationId");
  const [open, setOpen] = React.useState(false);

  const onOptionChanged = e => {
    if (e.fullName === "columns[2].filterValues") {
      setFilterValues(e.value);
    }
  };
  const handleClick = value => {
    setOpen(value);
  };

  const { orderedJobs, loading, reloadJobs, errors } =
    useQueryUseCaseCalculations(useCaseKey, useCaseVersion, null, null, 100);

  const selectScheduledCalculation = useCallback(
    api => {
      if (api) {
        if (calculationId) {
          const items = api.component.getDataSource().items();
          const rowWithSameCalculationId = find(items, { id: calculationId });
          rowWithSameCalculationId &&
            api.component.selectRows([rowWithSameCalculationId], true);
        } else {
          api.component.selectRowsByIndexes([0]);
        }
      }
    },
    [calculationId]
  );

  const StateCellRenderer = params => (
    <Tooltip title={params.value} classes={{ tooltip }}>
      <div data-testid="state">{params.value}</div>
    </Tooltip>
  );

  const hasCalculations =
    orderedJobs &&
    find(orderedJobs, el => get(el, "username.name") === signedInUser);

  useEffect(() => {
    if (hasCalculations) {
      setFilterValues([signedInUser]);
    }
  }, [signedInUser, hasCalculations]);

  const columnDefs = [
    {
      colDef: { dataField: "state", caption: "State", dataType: "string" },
      render: StateCellRenderer
    },
    {
      colDef: {
        dataField: "displayName",
        caption: "Input data",
        dataType: "string"
      }
    },
    {
      colDef: {
        dataField: "username.name",
        caption: "User",
        dataType: "string",
        selectedFilterOperation: "=",
        filterValues,
        minWidth: 250
      },
      render: p => {
        const username = p.data.username;
        return UserNameCellRenderer({ user: username });
      }
    },
    {
      colDef: {
        dataField: "created",
        caption: "Created",
        dataType: "datetime",
        minWidth: 250
      },
      render: params => (
        <Tooltip
          title={tooltipFormatter(params.data.created)}
          classes={{ tooltip }}
        >
          <div>
            <DateTickerCellRenderer value={params.value} />
          </div>
        </Tooltip>
      )
    },
    {
      colDef: {
        dataField: "started",
        caption: "Started",
        dataType: "datetime",
        allowFiltering: false
      },
      render: params => {
        const started = params.data.started;
        const created = params.data.created;
        const dateFormatter =
          started === null
            ? "pending"
            : `after ${formatDistanceStrict(started, created)}`;
        return (
          <Tooltip
            title={tooltipFormatter(params.data.started)}
            classes={{ tooltip }}
          >
            <div>{dateFormatter}</div>
          </Tooltip>
        );
      }
    },

    {
      colDef: {
        dataField: "completed",
        caption: "Completed",
        dataType: "datetime",
        allowFiltering: false
      },
      render: params => {
        const completed = params.data.completed;
        const started = params.data.started;
        const dateFormatter =
          completed === null
            ? "pending"
            : `in ${formatDistanceStrict(completed, started)}`;
        return (
          <Tooltip
            title={tooltipFormatter(params.data.completed)}
            classes={{ tooltip }}
          >
            <div>{dateFormatter}</div>
          </Tooltip>
        );
      }
    }
  ];
  const onSelectionHandler = params => {
    const selectedRow = params.selectedRowsData;
    setCalculationId(selectedRow[0].id);
  };
  const navigateToCalculationResult = id => navigateToCalculation(id);
  const debouncedRowClicked = debounce(navigateToCalculationResult, 500);

  const ErrorDialog = () => (
    <div className={flex}>
      <Tooltip title="Errors have occurred while loading the calculations, click this icon to see them.">
        <div>
          <IconButton
            className={errorIcon}
            onClick={e => {
              e.preventDefault();
              handleClick(true);
            }}
            size="large"
          >
            <Error />
          </IconButton>
        </div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => {
          handleClick(false);
        }}
        classes={{ paperScrollPaper: customWidth }}
      >
        <DialogContent className={dialogContent}>
          <ErrorBox
            apolloError={errors}
            title="Errors by loading the use case calculations!"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
  return loading ? (
    <LinearProgress data-testid="loading" />
  ) : (
    <div className={root}>
      <div className={header}>
        <div className={flex}>
          <Typography
            className={title}
          >{`Calculations for: ${useCaseKey}`}</Typography>
          {errors.length > 0 ? <ErrorDialog /> : null}
        </div>
      </div>
      <div
        data-testid="result-table"
        id="myGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <DataGridView
          columns={columnDefs}
          defaultColDef={{
            cellStyle: { overflow: "hidden" }
          }}
          dataSource={orderedJobs}
          onSelectionChanged={onSelectionHandler}
          onOptionChanged={onOptionChanged}
          withToolbar
          toolbarItemRender={() => (
            <div style={{ display: "flex", margin: 8 }}>
              {orderedJobs.length === 0 ? (
                <Button
                  onClick={() => navigateToCalculate()}
                  className={button}
                  variant="contained"
                  size="small"
                >
                  Schedule a calculation
                </Button>
              ) : null}
              <Tooltip title="Reload calculations">
                <div>
                  <Button
                    onClick={reloadJobs}
                    data-testid="reload-calculations"
                    className={button}
                    size="small"
                  >
                    Refresh
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}
          onRowClick={e => debouncedRowClicked(e.data.id)}
          onContentReady={selectScheduledCalculation}
        />
      </div>
    </div>
  );
};

export default UseCaseCalculationsResults;
