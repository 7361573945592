import { ApolloError, gql, useApolloClient, useQuery } from "@apollo/client";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { JobsCount, JobsCountVariables } from "./schema/JobsCount";
import {
  QueryUseCaseJobs,
  QueryUseCaseJobsVariables
} from "./schema/QueryUseCaseJobs";

export const jobsQuery = gql`
  query QueryUseCaseJobs(
    $useCaseKey: String!
    $useCaseVersion: String
    $scheduler: String
    $skipUntilId: ID
    $take: Int
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculations(
          scheduler: $scheduler
          skipUntilId: $skipUntilId
          take: $take
        ) {
          id
          detail {
            completed
            created
            started
            state
            username {
              firstName
              lastName
              name
            }
          }
          inputData {
            detail {
              metaData {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export const jobsCountQuery = gql`
  query JobsCount(
    $useCaseKey: String!
    $useCaseVersion: String
    $scheduler: String
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculationsCount(scheduler: $scheduler)
      }
    }
  }
`;

export const useQueryUseCaseJobsCount = (variables: JobsCountVariables) =>
  useQuery<JobsCount, JobsCountVariables>(jobsCountQuery, {
    variables
  });

export enum JobState {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

interface User {
  firstName: string | null;
  lastName: string | null;
  name: string;
}
export interface RowData {
  id: string;
  completed: Date | null;
  created: Date | null;
  started: Date | null;
  state: JobState;
  username: User;
  displayName: string | undefined;
}

export const useQueryUseCaseCalculations = (
  useCaseKey: string,
  useCaseVersion?: string | null,
  scheduler?: string | null,
  skipUntilId?: string | null,
  take?: number | null
) => {
  const client = useApolloClient();

  const [loading, setLoading] = useState<boolean>(true);
  const [allJobs, setAllJobs] = useState<RowData[]>([]);
  const [errors, setErrors] = useState<ApolloError[]>([]);
  const loadUseCaseJobs = useCallback(
    async (variables: QueryUseCaseJobsVariables) => {
      let { skipUntilId, take, ...rest } = variables;
      try {
        setLoading(true);
        const { errors, data } = await client.query<
          QueryUseCaseJobs,
          QueryUseCaseJobsVariables
        >({
          query: jobsQuery,
          variables: { skipUntilId, take, ...rest },
          errorPolicy: "all"
        });
        if (errors) {
          setErrors(e => [...e, new ApolloError({ graphQLErrors: errors })]);
        }
        const { useCase } = data.cloudPlatform;
        const calculations = useCase?.calculations?.map(
          ({
            id,
            detail: { completed, created, started, state, username },
            inputData
          }) => ({
            id,
            completed: completed ? new Date(completed) : null,
            created: created ? new Date(created) : null,
            started: started ? new Date(started) : null,
            state,
            username,
            displayName: inputData.detail?.metaData.displayName
              ? inputData.detail.metaData.displayName
              : undefined
          })
        );

        setAllJobs(calculations || []);
        setLoading(false);
      } catch (error) {
        setErrors(e => [error as ApolloError, ...e]);
        setLoading(false);
      }
    },
    [client]
  );
  useEffect(() => {
    const loadJobs = async () => {
      await loadUseCaseJobs({
        useCaseKey,
        useCaseVersion,
        scheduler,
        skipUntilId,
        take
      });
    };
    loadJobs();
  }, [
    loadUseCaseJobs,
    useCaseKey,
    useCaseVersion,
    scheduler,
    take,
    skipUntilId
  ]);

  const reloadJobs = useCallback(async () => {
    if (!useCaseKey) return;
    setAllJobs([]);

    await loadUseCaseJobs({
      useCaseKey,
      useCaseVersion,
      scheduler,
      skipUntilId: null,
      take
    });
  }, [loadUseCaseJobs, useCaseKey, useCaseVersion, scheduler, take]);

  const orderedJobs = _.orderBy(allJobs, a => a.created, ["desc"]);
  // return state
  return {
    orderedJobs,
    loading,
    reloadJobs,
    errors
  };
};
