import { TextField, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import React, { ChangeEvent } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    }
  })
);

type SelectProps = {
  options: any;
  label: string;
  inputValue?: string;
  name?: string;
  value?: any;
  error?: boolean;
  helperText?: React.ReactNode;
  onBlur?: React.FocusEventHandler;
  onChange: (event: ChangeEvent<{}>, value: any) => void;
  onInputChange?: (event: ChangeEvent<{}>, inputValue: string) => void;
  getOptionLabel: (option: any) => string;
  id: string;
};

export default function Select(props: SelectProps) {
  const jss = useStyles();
  return (
    <Autocomplete
      className={jss.root}
      getOptionLabel={props.getOptionLabel}
      options={props.options}
      renderInput={params => (
        <TextField
          {...params}
          name={props.name}
          helperText={props.helperText}
          error={props.error}
          label={props.label}
          variant="outlined"
          fullWidth
        />
      )}
      onBlur={props.onBlur}
      onChange={props.onChange}
      value={props.value}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      id={props.id}
    />
  );
}
