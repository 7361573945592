import { ApolloError } from "@apollo/client";
import { Error } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { getConfigValue } from "utils/config";
import TraceableErrorDetail from "./TraceableErrorDetail";
import { copyErrors, createTraceableErrors } from "./errorUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorBox: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F5F7F7",
      width: "100%",
      userSelect: "none"
    },
    errorBoxContent: {
      display: "flex",
      flexDirection: "column"
    },
    errorIconWrapper: { textAlign: "center" },
    errorIcon: {
      color: theme.palette.error.main,
      margin: theme.spacing(2)
    },
    errorTitle: {
      color: "#68777B",
      fontWeight: 400,
      fontSize: 20,
      textAlign: "center"
    },
    errorBoxActions: {
      backgroundColor: theme.palette.error.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    errorBoxButton: {
      color: "#FFFFFF",
      border: "none",
      margin: theme.spacing(1),
      "&:hover": {
        border: "none"
      }
    },
    environment: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.error.main,
      color: "#ffffff"
    },
    traceableError: {
      padding: theme.spacing(0, 1, 1, 1),
      "&>:nth-child(odd)>div>div": {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
      },
      "&>:nth-child(even)>div>div": {
        backgroundColor: "#ffffff"
      }
    }
  })
);

interface ErrorBoxProps {
  onClose?: (value: boolean) => void;
  apolloError: ApolloError | ApolloError[];
  title?: string;
  closable?: boolean;
  maxHeight?: number;
}
export default function ErrorBox({
  onClose,
  apolloError,
  title = "An error occurred",
  closable = false,
  maxHeight
}: ErrorBoxProps) {
  const classNames = useStyles({});
  const traceableErrors = createTraceableErrors(
    Array.isArray(apolloError) ? apolloError : [apolloError]
  );
  const environment = getConfigValue("ENVIRONMENT");
  return (
    <Grid item container>
      <div className={classNames.errorBox}>
        <div className={classNames.errorBoxContent}>
          {environment ? (
            <div className={classNames.environment}>{environment}</div>
          ) : null}
          <div className={classNames.errorIconWrapper}>
            <Error className={classNames.errorIcon} fontSize="large" />
          </div>
          {title ? (
            <Typography className={classNames.errorTitle}>{title}</Typography>
          ) : null}

          <Grid
            container
            direction="column"
            className={classNames.traceableError}
            spacing={2}
          >
            {traceableErrors.map(({ errorStacks }, index) => (
              <TraceableErrorDetail
                key={index}
                errorStacks={errorStacks}
                maxHeight={maxHeight}
              />
            ))}
          </Grid>
        </div>

        <div className={classNames.errorBoxActions}>
          {closable && (
            <Button
              className={classNames.errorBoxButton}
              variant="outlined"
              onClick={event => {
                onClose && onClose(false);
                event.preventDefault();
              }}
            >
              CLOSE
            </Button>
          )}
          <Button
            className={classNames.errorBoxButton}
            data-testid="copy-errors"
            variant="outlined"
            onClick={event => {
              event.preventDefault();
              copyErrors(traceableErrors);
            }}
          >
            COPY
          </Button>
        </div>
      </div>
    </Grid>
  );
}
