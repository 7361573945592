import { Dialog, DialogContent, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Error } from "@mui/icons-material";
import { ApolloError } from "@apollo/client";
import ErrorBox from "components/error/ErrorBox";
import React from "react";

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: "hidden",
    padding: 0,
    "&:first-child": {
      paddingTop: 0
    }
  },
  customWidth: {
    maxWidth: "none",
    minWidth: 400
  }
}));
export default function DataCountError({ error }: { error: ApolloError }) {
  const classNames = useStyles({});
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="An error has occurred while loading the data, click this icon to see it.">
        <Error
          fontSize="large"
          onClick={e => {
            e.preventDefault();
            handleOpen();
          }}
        />
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paperScrollPaper: classNames.customWidth }}
      >
        <DialogContent className={classNames.dialogContent}>
          <ErrorBox
            apolloError={error}
            title="Error by loading the use case data count!"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
