import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Link from "components/base/Link";
import { PrimaryButton } from "features/use-case-manager";

export const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(2)
  }
}));

const NoItemFound = ({
  title,
  linkTarget,
  linkCaption
}: {
  title: string;
  linkTarget: string;
  linkCaption: string;
}) => {
  const classNames = useStyles({});
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      direction="column"
      data-testid="topics-not-found"
    >
      <Typography className={classNames.margin}>{title}</Typography>
      <Link to={linkTarget}>
        <PrimaryButton>{linkCaption} </PrimaryButton>
      </Link>
    </Grid>
  );
};
export default NoItemFound;
