import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: "100%",
    display: "flex",
    color: "#fff"
  },
  left: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    height: 44,
    fontSize: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 14
    }
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
    alignItems: "center",
    height: 44
  },
  icon: { color: "#fff" }
}));
