import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Detail from "./Detail";
import { QueryApiDetail } from "./hooks/schema/QueryApiDetail";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1)
  }
}));

type ApiDataViewProps = {
  data: QueryApiDetail | undefined;
  loading?: boolean;
};

const ApiDataView: React.FC<ApiDataViewProps> = ({ data, loading }) => {
  const css = useStyles();
  const apiCalls = data?.cloudPlatform.useCase?.publicApi.apiCalls;
  const url = data?.cloudPlatform.useCase?.publicApi.url;
  return (
    <Grid item container>
      <div className={css.root}>
        <Detail
          title="Api Calls"
          content={apiCalls?.toString()}
          dense
          loading={loading}
          contentSkeletonWidth={50}
        />

        <Detail
          title="URL"
          content={url}
          contentCopy={true}
          dense
          loading={loading}
          contentSkeletonWidth={300}
        />
      </div>
    </Grid>
  );
};

export default ApiDataView;
