import { ProjectDefinitionInput } from "model/schema/globalTypes";
import { useReducer } from "react";

export interface State {
  name?: ProjectDefinitionInput["name"];
  description?: ProjectDefinitionInput["description"];
  image?: ProjectDefinitionInput["image"];
  workspaceId?: ProjectDefinitionInput["workspaceId"] | null;
  databaseTemplateId?: ProjectDefinitionInput["databaseTemplateId"] | null;
  tags?: ProjectDefinitionInput["tags"];
  loadImage?: boolean;
}

export type Action =
  | { type: "SET_NAME"; value: string }
  | { type: "SET_DESCRIPTION"; value: string }
  | { type: "SET_WORKSPACE"; value?: string }
  | { type: "SET_DATABASE_TEMPLATE"; value: string }
  | { type: "SET_TAG"; value: string }
  | { type: "DELETE_TAG"; value: string }
  | { type: "LOAD_ICON"; value: boolean }
  | { type: "DROP_ICON"; value: string | ArrayBuffer | null }
  | { type: "RESET" };

export default function usePublishProjectReducer(initialState?: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_NAME":
        return { ...state, name: action.value };
      case "SET_DESCRIPTION":
        return { ...state, description: action.value };
      case "SET_DATABASE_TEMPLATE":
        return { ...state, databaseTemplateId: action.value };
      case "SET_WORKSPACE":
        return { ...state, workspaceId: action.value };
      case "SET_TAG":
        const tags = state.tags
          ? [...state.tags, action.value]
          : [action.value];
        return { ...state, tags };
      case "DELETE_TAG":
        return {
          ...state,
          tags: state.tags && state.tags.filter(tag => tag !== action.value)
        };
      case "LOAD_ICON":
        return { ...state, loadImage: action.value };
      case "DROP_ICON":
        const image =
          typeof action.value === "string" ? action.value : undefined;
        return { ...state, image, loadImage: false };
      case "RESET":
        return { ...initialState };
      default:
        throw new Error();
    }
  }

  return useReducer(
    reducer,
    initialState || { name: "", databaseTemplateId: null, workspaceId: null }
  );
}
