import { AddCircle, SearchRounded } from "@mui/icons-material";
import { Grid, InputBase, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import LinkIconButton from "components/base/LinkIconButton";
import { Permission, RestrictedRealm } from "features/access-management";
import { ActionBarContent } from "features/shell";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useSearchQueryParam } from "utils/url";
import useQueryUseCaseList from "./hooks/useQueryUseCaseList";
import UseCaseCard from "./UseCaseCard";

const useStyles = makeStyles(theme => ({
  searchBar: {
    width: 300,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(1)}`,
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)"
  }
}));

export default function UseCases() {
  const classNames = useStyles();
  const { data, loading } = useQueryUseCaseList();
  const [searchValue, setSearchValue] = useSearchQueryParam("search");
  const searchInputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  const useCases = useMemo(() => {
    if (!data) return [];
    let result = data.cloudPlatform.useCases;
    if (!result) return [];
    if (!isEmpty(searchValue)) {
      result = result.filter(useCase => {
        return `${useCase.detail.name} ${useCase.detail.tags.join(" ")}`
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());
      });
    }

    return result.sort((a, b) => a.detail.name.localeCompare(b.detail.name));
  }, [data, searchValue]);
  return (
    <>
      {loading && <LinearProgress />}
      <ActionBarContent>
        <div className={classNames.searchBar}>
          <InputBase
            placeholder="Search a use case"
            // className={input}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            inputRef={searchInputRef}
          />
          <SearchRounded color="secondary" />
        </div>
        <RestrictedRealm
          requiredPermissions={[Permission.ChangeUseCases]}
          silent
        >
          <LinkIconButton
            to={"/use-case-manager/create"}
            title="Add use case"
            testId="add-use-case"
            icon={AddCircle}
          />
        </RestrictedRealm>
      </ActionBarContent>
      <Grid container justifyContent="center" spacing={4}>
        {useCases.map(({ detail: { key, ...useCaseDetail } }) => (
          <UseCaseCard useCaseKey={key} {...useCaseDetail} key={key} />
        ))}
      </Grid>
    </>
  );
}
