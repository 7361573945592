import { ApolloError } from "@apollo/client";
import copy from "copy-to-clipboard";
import { GraphQLError } from "graphql";

const errorStackSeparator = " ---> ";
const errorStackItemSeparator = ": ";

type ErrorStackItem = {
  type: string;
  content: string;
};

export type ErrorStack = ErrorStackItem[];

export type TraceableError = {
  errorStacks: ErrorStack[];
  traceId?: string | unknown;
};

export const createTraceableErrors = (
  errors: ApolloError[]
): TraceableError[] => {
  return errors.map(({ message, graphQLErrors }) => {
    if (graphQLErrors?.length > 0) {
      var traceId = graphQLErrors[0]?.extensions?.traceId;
      var errorMessages = graphQLErrors.map(e => e.message);
      var errorStacks = errorMessages.map(parseErrorStack);
      return { traceId, errorStacks };
    } else {
      return {
        traceId: undefined,
        errorStacks: [[{ type: "", content: message }]]
      };
    }
  });
};

export const parseErrorStack = (message: string): ErrorStack => {
  const stackMessages = message.split(errorStackSeparator);
  return stackMessages.map(parseErrorStackItem);
};

const parseErrorStackItem = (stackMessage: string): ErrorStackItem => {
  const parts = stackMessage.split(errorStackItemSeparator);
  const [type, ...contentParts] = parts;

  if (!type || contentParts.length === 0) {
    throw new Error("Message doesn't contain an error stack item");
  }

  return {
    type: type,
    content: contentParts.join(errorStackItemSeparator)
  };
};

export const copyErrors = (errors: TraceableError[]) => {
  const clipboard = JSON.stringify(errors);
  copy(clipboard);
};

/** 
    Transforms a graphql error stack into a better readable stack by reducing error types and redundancy which do not contain any additional information.
  */
export const simplifyErrorStack = (verboseStack: ErrorStack): ErrorStack => {
  const ignoredErrorTypes = ["System.AggregateException"];
  const ignoredContents = [
    "Exception has been thrown by the target of an invocation."
  ];

  let stack = verboseStack
    .filter(item => !ignoredErrorTypes.includes(item.type))
    /* keep inner most stack item on same content */
    .filter(
      (item, index, completeStack) =>
        !completeStack
          .slice(index + 1)
          .find(successorItem => successorItem.content === item.content)
    )
    /* ignore contents */
    .filter(item => !ignoredContents.includes(item.content));

  return stack;
};

export const createGraphQLError = (errorStack: ErrorStack, traceId: string) =>
  new GraphQLError(
    createErrorMessage(errorStack),
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    {
      traceId
    }
  );

export const createErrorMessage = (errorStack: ErrorStack) => {
  return errorStack
    .map(({ type, content }) => `${type}${errorStackItemSeparator}${content}`)
    .join(errorStackSeparator);
};
