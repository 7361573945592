import {
  Card,
  CardActionArea,
  CardActions,
  Grid,
  Tooltip,
  Typography
} from "@mui/material";

import RouterLink from "components/base/Link";
import NoLink from "components/base/NoLink";
import React, { useState } from "react";
import { useStyles } from "./BasicCardStyle";
import Tags from "./Tags";

type BasicCardProps = {
  disabled?: boolean;
  name: string;
  subtitle?: string;
  icon?: string | null;
  tags: string[] | null;
  description?: string | null;
  linkTo?: string;
  children?: React.ReactNode;
};

export default function BasicCard({
  disabled,
  name,
  subtitle,
  icon,
  tags = [],
  description = "",
  linkTo,
  children = null
}: BasicCardProps) {
  const {
    card,
    container,
    backdrop,
    link,
    title,
    image,
    right,
    left,
    leftMiddle,
    descriptionClass,
    tagsList,
    tagsListItem,
    iconWrapper,
    titleContainer,
    actions,
    subtitleText,
    flex,
    tooltip
  } = useStyles({});
  const [showAllTags, setShowAllTags] = useState(false);

  const Link = linkTo ? RouterLink : NoLink;
  return (
    <Card className={card}>
      <div className={disabled ? backdrop : undefined} />
      <CardActionArea disabled={disabled}>
        <Link className={link} to={linkTo!}>
          <div className={container}>
            <div className={left}>
              <div className={titleContainer}>
                <Tooltip title={name}>
                  <Typography variant="subtitle1" className={title}>
                    {name}
                  </Typography>
                </Tooltip>
              </div>
              {subtitle && (
                <Typography variant="subtitle2" className={subtitleText}>
                  {subtitle}
                </Typography>
              )}
              <div className={leftMiddle}>
                {showAllTags ? (
                  <ul className={tagsList}>
                    {tags?.slice(0, 6).map(tag => (
                      <li key={tag} className={tagsListItem}>
                        <Typography variant="caption">{`# ${tag}`}</Typography>
                      </li>
                    ))}
                  </ul>
                ) : description ? (
                  <Tooltip
                    title={description}
                    classes={{
                      tooltip
                    }}
                  >
                    <Typography variant="caption" className={descriptionClass}>
                      {description}
                    </Typography>
                  </Tooltip>
                ) : null}
              </div>
            </div>
            <div className={right}>
              <div className={iconWrapper}>
                {icon ? <img src={icon} className={image} alt="" /> : null}
              </div>
            </div>
          </div>
        </Link>
      </CardActionArea>
      <CardActions className={actions}>
        <Grid item className={flex}>
          <Tags tags={tags} setShowAllTags={setShowAllTags} />
          {!disabled && children}
        </Grid>
      </CardActions>
    </Card>
  );
}
