import {
  Button,
  CircularProgress,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";

import clsx from "clsx";
import Link from "components/base/Link";
import { LinkProps } from "react-router-dom";
import { useStyles } from "./TopicStyle";

type TopicContentProps = {
  icon: React.ReactNode;
  title: string;
  to: LinkProps["to"];
  disabledMessage?: React.ReactNode;
  isLoading?: boolean;
  dataTest?: string;
  children: React.ReactNode;
  colorClassName?: string;
  count?: React.ReactNode;
};

type TopicProps = TopicContentProps & {
  toolTipTitle?: string;
};

const TopicContent: React.FC<TopicContentProps> = ({
  icon,
  title,
  to,
  disabledMessage,
  isLoading = false,
  dataTest,
  children,
  colorClassName,
  count
}) => {
  const { topicInner, progress, link, disabledLink, button, text, left, flex } =
    useStyles({});
  const disabled = isLoading || Boolean(disabledMessage);
  return (
    <Link
      to={to}
      className={disabled ? disabledLink : clsx(link, colorClassName)}
    >
      <Button data-test={dataTest} fullWidth className={button} key={dataTest}>
        <div className={topicInner}>
          <Typography className={text}>{title}</Typography>

          <div className={flex}>
            <div className={left}>
              {isLoading && (
                <CircularProgress className={progress} color="secondary" />
              )}
              {icon}
            </div>
            {count}
            {children}
          </div>
        </div>
      </Button>
    </Link>
  );
};

const Topic: React.FC<TopicProps> = ({
  disabledMessage = undefined,
  toolTipTitle,
  title,
  ...contentProps
}) => {
  const { disabledSpan } = useStyles({});

  return Boolean(disabledMessage) ? (
    <Tooltip title={<Typography color="inherit">{disabledMessage}</Typography>}>
      <span className={disabledSpan}>
        <TopicContent
          data-topic-id={title + "disabled"}
          {...contentProps}
          title={title}
        />
      </span>
    </Tooltip>
  ) : toolTipTitle ? (
    <Tooltip title={toolTipTitle}>
      <span>
        <TopicContent {...contentProps} title={title} />
      </span>
    </Tooltip>
  ) : (
    <TopicContent {...contentProps} title={title} />
  );
};

export default Topic;
