import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import {
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schema,
  NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schemaDiff
} from "features/use-case-manager";
import {
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff,
  SchemaDiff_cloudPlatform_useCase_schema
} from "./components/schema/SchemaDiff";
import TechnicalChangelog from "./TechnicalChangelog";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    top: "10vh",
    left: "10vw",
    width: "80vw",
    height: "80vh",
    overflow: "hidden",
    padding: theme.spacing(2),
    display: "flex"
  },
  content: {
    paddingTop: theme.spacing(2),
    height: "calc(100% - 30px)"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%"
  },
  flex: { display: "flex", justifyContent: "space-between" }
}));

const TechnicalChangelogButton = ({
  schema,
  schemaDiff,
  loading,
  isChangelogOpened,
  toggleChangelog
}: {
  schema?:
    | SchemaDiff_cloudPlatform_useCase_schema
    | NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schema;
  schemaDiff?:
    | SchemaDiff_cloudPlatform_useCase_detail_schemaDiff
    | NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schemaDiff
    | null;
  loading?: boolean;
  isChangelogOpened: boolean;
  toggleChangelog: () => void;
}) => {
  const classNames = useStyles();

  return (
    <>
      <Tooltip title={<Typography>Current version changelog</Typography>}>
        <IconButton size="small" onClick={toggleChangelog}>
          <UpdateIcon />
        </IconButton>
      </Tooltip>
      <Modal open={isChangelogOpened} onClose={toggleChangelog}>
        <Paper className={classNames.paper}>
          {loading && <LinearProgress />}
          {schemaDiff && schema && (
            <div className={classNames.root}>
              <div className={classNames.flex}>
                <Typography> Changelog </Typography>
                <IconButton
                  size="small"
                  onClick={toggleChangelog}
                  data-testid="changelog-close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classNames.content}>
                <TechnicalChangelog schemaDiff={schemaDiff} schema={schema} />
              </div>
            </div>
          )}
        </Paper>
      </Modal>
    </>
  );
};

export default TechnicalChangelogButton;
