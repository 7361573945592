import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { TagBox } from "devextreme-react";
import { difference } from "lodash";
import { Dispatch } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tags: {
      cursor: "text",
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      "& .dx-tag-content": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1, 4, 1, 1)
      },
      "& .dx-tag-remove-button::after, .dx-tag-remove-button::before": {
        background: theme.palette.common.white
      }
    }
  })
);

type Action =
  | { type: "SET_TAG"; value: string }
  | { type: "DELETE_TAG"; value: string };

type TagsInputProps = {
  tags: string[];
  dispatch: Dispatch<Action>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
};

const TagsInput = ({ tags, dispatch, setFieldValue }: TagsInputProps) => {
  const classes = useStyles();
  const maxTags = 16;
  const maxLengthPerTag = 32;
  return (
    <TagBox
      value={tags}
      deferRendering={false}
      openOnFieldClick={false}
      placeholder="Tags"
      searchEnabled={true}
      showDropDownButton={false}
      dropDownOptions={{
        onShowing: e => {
          e.cancel = true;
        }
      }}
      acceptCustomValue={true}
      onKeyDown={e => {
        if (tags.length >= maxTags) {
          e.event?.preventDefault();
          e.event?.stopPropagation();
        }
      }}
      onCustomItemCreating={e => {
        e.customItem = e.text;
        if (e.text && !tags.includes(e.text)) {
          dispatch({ type: "SET_TAG", value: e.text });
          setFieldValue && setFieldValue("tags", e.text);
        }
      }}
      onValueChanged={e => {
        const newValues = e.value;
        const previousValue = e.previousValue;
        const eventType = e.event?.type;
        if (eventType === "dxclick") {
          const tagsDiff = difference(previousValue, newValues);
          if (tagsDiff[0]) {
            dispatch({
              type: "DELETE_TAG",
              value: tagsDiff[0] as string
            });
            setFieldValue && setFieldValue("tags", tagsDiff[0]);
          }
        }
      }}
      maxLength={maxLengthPerTag}
      className={classes.tags}
    />
  );
};
export default TagsInput;
