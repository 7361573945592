import { Avatar, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme, UserAvatarProps>(theme => ({
  avatar: ({ large = false }) => ({
    color: "#3AA9AE",
    borderRadius: "0.75rem",
    backgroundColor: "#C9F7F5",
    fontFamily: "monospace",
    fontSize: large ? "24px" : undefined,
    width: large ? 50 : undefined,
    height: large ? 50 : undefined
  })
}));

export interface INameProvider {
  firstName: string | null;
  lastName: string | null;
}

export function createUserAvatarCaption(
  firstName: string,
  lastName: string
): string {
  const shorthand =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  return shorthand || "?";
}
type UserAvatarProps = {
  user: INameProvider;
  large?: boolean;
  dataTest?: string;
};

export default function UserAvatar(props: UserAvatarProps) {
  const { avatar } = useStyles(props);
  const { user, dataTest = "user" } = props;
  return user ? (
    <Avatar data-test={dataTest} className={avatar}>
      {user.firstName !== null && user.lastName !== null
        ? createUserAvatarCaption(user.firstName, user.lastName)
        : "API"}
    </Avatar>
  ) : null;
}
