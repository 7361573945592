import { gql, useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import Link from "components/base/Link";
import Tags from "components/base/Tags";

import React, { useState } from "react";
import defaultIcon from "tile.svg";
import { UseCaseDetail } from "../hooks/topicsHooks";
import TechnicalChangelogButton from "../TechnicalChangelogButton";
import { SchemaDiff, SchemaDiffVariables } from "./schema/SchemaDiff";
import { useStyles } from "./UseCaseDetailStyle";

const QuerySchemaDiff = gql`
  query SchemaDiff($useCaseKey: String!, $useCaseVersion: String!) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        schema {
          input {
            columns {
              nullable
              name
              typeName
            }
            name
          }
          output {
            columns {
              name
              nullable
              typeName
            }
            name
          }
        }
        detail {
          releaseType
          version
          schemaDiff {
            input {
              changedTables {
                name
                changedColumns {
                  from {
                    name
                    nullable
                    typeName
                  }
                  to {
                    name
                    nullable
                    typeName
                  }
                }
                missingColumns {
                  name
                  nullable
                  typeName
                }
                newColumns {
                  name
                  nullable
                  typeName
                }
              }
              missingTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
              newTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
            }
            output {
              changedTables {
                name
                changedColumns {
                  from {
                    name
                    nullable
                    typeName
                  }
                  to {
                    name
                    nullable
                    typeName
                  }
                }
                missingColumns {
                  name
                  typeName
                  nullable
                }
                newColumns {
                  name
                  nullable
                  typeName
                }
              }
              missingTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
              newTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UseCaseDetails: React.FC<UseCaseDetail> = ({
  image,
  version,
  name,
  description,
  tags,
  useCaseKey
}) => {
  const classNames = useStyles({});
  const [isChangelogOpened, setIsChangelogOpened] = useState(false);

  const toggleChangelog = () => setIsChangelogOpened(!isChangelogOpened);
  const { loading, data } = useQuery<SchemaDiff, SchemaDiffVariables>(
    QuerySchemaDiff,
    {
      variables: { useCaseKey, useCaseVersion: version },
      skip: !isChangelogOpened
    }
  );
  return (
    <div className={classNames.detail}>
      <div className={classNames.detailInner}>
        {image ? (
          <img src={image} className={classNames.image} alt={version} />
        ) : (
          <div className={classNames.imgInner}>
            <img
              src={defaultIcon}
              className={classNames.defaultIcon}
              alt="default"
            />
          </div>
        )}

        <div
          className={classNames.anotherDetails}
          data-testid="use-case-overview-name"
        >
          <Typography className={classNames.name}>{name}</Typography>
          <Typography className={classNames.version}>
            {version}
            {version !== "1.0.0" && (
              <TechnicalChangelogButton
                loading={loading}
                schemaDiff={data?.cloudPlatform.useCase?.detail.schemaDiff}
                schema={data?.cloudPlatform.useCase?.schema}
                isChangelogOpened={isChangelogOpened}
                toggleChangelog={toggleChangelog}
              />
            )}
          </Typography>
        </div>
        <Link to="./public-api">
          <Button
            data-testid="topic-public-api"
            className={classNames.apiButton}
            variant="contained"
            color="primary"
          >
            PUBLIC API
          </Button>
        </Link>
      </div>
      {description ? (
        <Typography className={classNames.description}>
          {description}
        </Typography>
      ) : null}
      <Tags tags={tags} />
    </div>
  );
};

export default UseCaseDetails;
