import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router";
import { createRoute } from "utils/url";
import invalidRouteImage from "./invalidRoute.svg";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",

    justifyContent: "center",
    alignItems: "center"
  },
  errorTitle: {
    color: theme.palette.error.main,
    margin: 0
  },
  error: { color: "#191919", marginTop: theme.spacing(3) },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.8
    }
  },
  margin: { marginTop: theme.spacing(3) },
  left: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  right: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  image: { height: 200, width: 200 }
}));
export default function InvalidRoute() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <h1 className={classes.errorTitle}>404</h1>
        <h1 className={classes.errorTitle}>Page not found</h1>
        <Typography className={classes.error}>
          We could not find the page you are looking for.
        </Typography>
        <Typography className={classes.error}>
          Check for spelling mistakes in the page address or use another link
        </Typography>
        <div className={classes.margin}>
          <Button
            variant="contained"
            onClick={() =>
              history.push(createRoute(`${process.env.PUBLIC_URL}/`))
            }
            className={classes.button}
          >
            HOME PAGE
          </Button>
        </div>
      </div>
      <div className={classes.right}>
        <img src={invalidRouteImage} alt="svg" className={classes.image} />
      </div>
    </div>
  );
}
