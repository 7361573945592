import { Grid, LinearProgress, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TechnicalChangelogButton } from "features/use-case";
import { ReleaseType } from "model/schema/globalTypes";
import React, { useState } from "react";
import useQueryChangePreview, {
  NextVersionInfoProps,
  Schema,
  SchemaDiff
} from "./hooks/useQueryChangePreview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
      width: "100%"
    },
    version: {
      color: "#B1B1B1"
    },
    margin: { marginTop: theme.spacing(1) }
  })
);

type ChangesPreviewComponentProps = {
  version: string;
  releaseType: ReleaseType;
  schemaDiff?: SchemaDiff;
  schema?: Schema;
};

const ReleasePreview: React.FC<ChangesPreviewComponentProps> = ({
  schemaDiff,
  schema,
  version,
  releaseType
}) => {
  const classNames = useStyles();
  const [isChangelogOpened, setIsChangelogOpened] = useState(false);
  const toggleChangelog = () => setIsChangelogOpened(!isChangelogOpened);

  return (
    <>
      <Grid item container data-testid="version" className={classNames.margin}>
        <Grid item container xs={6}>
          <Typography className={classNames.version}>
            Expected next version
          </Typography>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Typography className={classNames.version}>{version}</Typography>
        </Grid>
      </Grid>

      <Grid item container data-testid="releaseType">
        <Grid item container xs={6}>
          <Typography className={classNames.version}>
            Expected release type
          </Typography>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Typography className={classNames.version}>{releaseType}</Typography>
        </Grid>
      </Grid>

      {schema && schemaDiff && (
        <>
          <Grid item container data-testid="changelog">
            <Grid item container xs={6}>
              <Typography variant="subtitle2">Show Changes</Typography>
            </Grid>
            <TechnicalChangelogButton
              schemaDiff={schemaDiff}
              schema={schema}
              isChangelogOpened={isChangelogOpened}
              toggleChangelog={toggleChangelog}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const ChangesPreview = ({
  useCaseKey,
  calculationRuleAddress
}: NextVersionInfoProps) => {
  const jss = useStyles();

  const { data, loading } = useQueryChangePreview({
    useCaseKey,
    calculationRuleAddress
  });

  if (loading) return <LinearProgress className={jss.progress} />;
  if (!data) return null;

  const { useCase } = data?.cloudPlatform;
  if (useCase === null) return <InitialVersionPreview />;
  const nextVersion = useCase.detail.nextVersion;
  if (!nextVersion) return null;
  const { releaseType, schemaDiff, schema, version } = nextVersion;

  return (
    <ReleasePreview
      version={version}
      releaseType={releaseType}
      schema={schema}
      schemaDiff={schemaDiff}
    />
  );
};

export default ChangesPreview;

const InitialVersionPreview = () => {
  return <ReleasePreview version="1.0.0" releaseType={ReleaseType.Initial} />;
};
