import makeStyles from '@mui/styles/makeStyles';
import { useProjectName } from "./hooks/projectsHooks";

const useStyles = makeStyles(() => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface ProjectNameProps {
  projectId: string;
}

export default function ProjectName({ projectId }: ProjectNameProps) {
  const { root } = useStyles({});
  const { data } = useProjectName({
    projectId
  });

  const name = data?.projects.project?.name;
  return (
    <div data-testid="project-details-nav" className={root}>
      {name || projectId}
    </div>
  );
}
